import React, { useState } from 'react';
import './NumPeople.css';

const NumPeople = ({ setNumberOfAdults }) => {
  const [adults, setAdults] = useState(1);  // Initialize to 1

  const handleAdultsChange = (newCount) => {
    setAdults(newCount);
    setNumberOfAdults(newCount);
  };

  return (
    <div className="num-people-container">
      <div className="age-group">
        <span className="age-group-label">Adults (18-100)</span>
        <button onClick={() => handleAdultsChange(adults - 1)} disabled={adults <= 1}>-</button>
        <span>{adults}</span>
        <button onClick={() => handleAdultsChange(adults + 1)}>+</button>
      </div>
    </div>
  );
};

export default NumPeople;





