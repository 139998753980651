import React, { useState, useEffect } from 'react';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Stripe from 'stripe'; // Importing for simulated server-side logic
import './PaymentModal.css';


const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4"
      }
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a"
    }
  }
};

// Initialize Stripe
const stripePromise = loadStripe("pk_live_51NlJNFGd9lEx9hUOP0JV5JxH54WizUeLUyalKjb0vP7cubCoLoSuScokr3ulwwlaowL2PHyOAcWbILB881xWAuih00Jqj8PI9b");
const stripeServer = new Stripe('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'); // Simulated server-side Stripe object

const CheckoutForm = ({ totalPrice, email: initialEmail }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [email, setEmail] = useState(initialEmail);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (isProcessing) {
      // Start the progress bar
      let progressInterval = setInterval(() => {
        setProgress((prevProgress) => {
          if (prevProgress >= 100) {
            clearInterval(progressInterval);
            return 100;
          }
          return prevProgress + 10; // Increase by 10% every 200ms
        });
      }, 200);
      
      return () => clearInterval(progressInterval); // Clear interval on component unmount
    }
  }, [isProcessing]);

  useEffect(() => {
    //  server-side logic to create a PaymentIntent
    const createPaymentIntent = async () => {
      
      const customer = await stripeServer.customers.create({
        email: email,
      });

      const paymentIntent = await stripeServer.paymentIntents.create({
        amount: totalPrice * 100, // Convert to cents
        currency: 'eur',
        customer: customer.id,
      });
      setClientSecret(paymentIntent.client_secret);
    };

    createPaymentIntent();
  }, [totalPrice, email]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsProcessing(true);
  
    if (!stripe || !elements) {
      setIsProcessing(false);
      return;
    }
  
    const cardElement = elements.getElement(CardElement);
  
    const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: cardElement,
      },
      receipt_email: email,
    });
  
    if (error) {
      console.log('[error]', error);
      setPaymentStatus('failed');
    } else {
      if (paymentIntent.status === 'succeeded') {
        console.log('Payment successful');
        setPaymentStatus('succeeded');
  
        // Simulate sending Stripe receipt to email
        console.log(`Stripe receipt would be sent to ${email}`);
        // Here you can call an API to send an email receipt
      } else {
        console.log('Payment failed');
        setPaymentStatus('failed');
      }
    }
  
    setIsProcessing(false);
  };
 return (
  <div>
    <div className="email-price-container">
      <div>
        <label>Email</label>
        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required readOnly />
      </div>
      <div>
        <label className="label">Booking fees:</label>
        <span>€0.00</span>
      </div>
      <div>
        <label className="label">Total Price:</label>
        <span className="price-display">€{totalPrice}</span>
      </div>
    </div>

    <form onSubmit={handleSubmit} className="card-input">
      <div className="form-group">
        <label className="label">Tour ticket trip ⚫️</label>
        <CardElement options={CARD_ELEMENT_OPTIONS} />
      </div>
      <button className="apple-btn" type="submit" disabled={!stripe || isProcessing}>
        {isProcessing ? "Processing..." : "Pay Now"}
      </button>
      {isProcessing && (
        <div className="progress-container">
          <div className="progress-bar" style={{ width: `${progress}%` }}></div>
        </div>
      )}
    </form>

    {paymentStatus === 'succeeded' && <p>Payment accepted! ✅</p>}
    {paymentStatus === 'failed' && <p>Payment failed. Please try again.❌</p>}
  </div>
);
};


const PaymentModal = ({ isOpen, close, totalPrice, email }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="payment-modal">
      <div className="payment-modal-content">
        <button className="payment-modal-close" onClick={close}>
          &times;
        </button>
        <h2>Payment</h2>
        <Elements stripe={stripePromise}>
          <CheckoutForm totalPrice={totalPrice} email={email} />
        </Elements>
      </div>
    </div>
  );
};

export default PaymentModal;



//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

//loadStripe("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"); // Replace with your Stripe public key
