import React, { useState } from 'react';
import './FormPage.css';



function FormPage() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('../netlify/functions/sendEmail', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData)
            });

            if (response.ok) {
                console.log('Email sent successfully');
                setFormData({ name: '', email: '', message: '' }); // Reset form data
                alert('Your message has been sent successfully!'); // Notify user
            } else {
                console.error('Failed to send email', await response.text());
                alert('Failed to send your message. Please try again later.');
            }
        } catch (error) {
            console.error("Error:", error);
            alert('An error occurred. Please try again later.');
        }
    };

    return (
        <div className="form-container">
            <h2>Contact Us</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>Name:</label>
                    <input 
                        type="text" 
                        name="name" 
                        value={formData.name} 
                        onChange={handleChange} 
                        required 
                        placeholder="Enter your name"
                    />
                </div>
                <div className="form-group">
                    <label>Email:</label>
                    <input 
                        type="email" 
                        name="email" 
                        value={formData.email} 
                        onChange={handleChange} 
                        required 
                        placeholder="Enter your email"
                    />
                </div>
                <div className="form-group">
                    <label>Message:</label>
                    <textarea 
                        name="message" 
                        value={formData.message} 
                        onChange={handleChange} 
                        required 
                        placeholder="Type your message here..."
                        rows="5"
                    />
                </div>
                <button type="submit">Submit</button>
            </form>
            <a
                href="https://wa.me/33668796750?text=Hello!"
                target="_blank"
                rel="noopener noreferrer"
                className="whatsapp-button"
            >
                Contact via WhatsApp
            </a>
        </div>
    );
}

export default FormPage;
