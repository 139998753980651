import React, { useState, useEffect,useRef } from 'react';
import './Header.css';
import TranslateWidget from './TranslateWidget';

function Header() {
  const [menuActive, setMenuActive] = useState(false); // State to track if the menu is active
  const [currency, setCurrency] = useState('EUR'); // State to track selected currency
  const [language, setLanguage] = useState('en');

 


  return (
    <header className="notranslate header">
      
      <div className="logo-container">
        <h1>Tour Ticket Trip ✈</h1>
      </div>
      
      <nav>
        <ul className={`nav-links ${menuActive ? 'active' : ''}`}>
          <li><a href="/">Home</a></li>
          <li><a href="/tours">Activities</a></li>
          <li><a href="/contact">Contact</a></li>
          <li><a href="/contact">Transportations</a></li>
          <li><a href="/contact">Pro</a></li>
        </ul>
      </nav>
      <div className="currency-language-container">
        <select onChange={(e) => setCurrency(e.target.value)} value={currency}>
          <option value="EUR">€ - EUR</option>
          {/* Add more currency options here */}
        </select>
        <TranslateWidget/>
        ⚜️
      </div>
      
    </header>
  );
}

export default Header;


//<select onChange={(e) => setLanguage(e.target.value)} value={language}>
       //   <option value="ENG">ENG</option>
       //  <option value="ENG">FR</option>
        //  <option value="ENG">ESP</option>
       //   {/* Add more language options here */}
       // </select>