// HourSelector.js
import React from 'react';
import './HourSelector.css'; // Create this CSS file to style your HourSelector component

const HourSelector = ({ selectedHour, handleHourClick, hours = [] }) => {
  
  return (
    <div className="hour-selector">
      {hours.map((hour, index) => (
        <button 
          key={index} 
          className={`hour-button ${selectedHour === hour ? 'selected-hour' : ''}`}
          onClick={() => handleHourClick(hour)}
        >
          {hour}
        </button>
      ))}
    </div>
  );
};

export default HourSelector;
