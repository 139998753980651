import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { db } from './firebase';
import { collection, getDocs } from 'firebase/firestore';
import './Tours.css';
import { useParams } from 'react-router-dom';
import Logo from '../images/ticket.jpg'



function Tours() {
  const [tours, setTours] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const { category } = useParams();  // Extract category from the URL`

  function getPriceRating(price) {
    if (price <= 20) return "✤";
    if (price <= 40) return "✤✤";
    if (price <= 60) return "✤✤✤";
    if (price <= 80) return "✤✤✤✤";
    return "✤✤✤✤✤";
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const tourCollection = collection(db, 'tours');
        const tourSnapshot = await getDocs(tourCollection);
        const tourList = tourSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
        if (category) {
          setTours(tourList.filter(tour => tour.category === category));
        } else {
          setTours(tourList);
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [category]);

  const filteredTours = tours.filter(tour => 
    tour.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    
    <div className="toursPage-container">
      <p className="toursPage-image"></p>
      <h1 className="toursPage-title">All Tours</h1>
      
      <input 
        className="toursPage-search"
        type="text" 
        placeholder="Search Tours" 
        value={searchQuery}
        onChange={e => setSearchQuery(e.target.value)}
      />
      {isLoading ? (
        <p className="toursPage-loading">Loading...</p>
        
      ) : (
        <div className="toursPage-list">
          {filteredTours.map((tour) => (
           <Link to={`/tour/${tour.id}`} className="toursPage-item" key={tour.id}>
           <h2 className="toursPage-name">{tour.name}</h2>
           <p className="toursPage-description">{tour.descriptionSmall}</p>
           <p className="toursPage-price">Price: {tour.price}€</p>
          
         <p className="toursPage-price2">{getPriceRating(tour.price)}</p>
         <p className="tour-price3">go ➢</p>
           
           
         </Link>
          ))}
        </div>
      )}
    </div>
  );
}

export default Tours;
