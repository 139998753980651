import React, { useState, useEffect } from 'react';
import './HomePage.css';
import { Link } from 'react-router-dom';
import parisImage from '../images/paris22Home.jpeg';
import { db } from './firebase';
import { collection, getDocs } from 'firebase/firestore';
import HeroSection from './HeroSection';
import 'font-awesome/css/font-awesome.min.css';

function HomePage() {
  const [tours, setTours] = useState([]);
  const [showCount, setShowCount] = useState(16);
  const [packs, setPacks] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const tourCollection = collection(db, 'tours');
        const tourSnapshot = await getDocs(tourCollection);
        const tourList = tourSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
        const topTours = tourList.filter(tour => tour.topTour);  // Filter tours with topTour set to true
        setTours(topTours);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, []);


  
  return (
    <main className="homepage">
      <HeroSection className="hero"/>
      

      <div className="category-list">
    <Link to="/tours/Outdoor Activities" className="category-item">
        <i className="fa fa-tree category-icon" aria-hidden="true"></i>
        Outdoor Activities
    </Link>
    <Link to="/tours/Cultural Activities" className="category-item">
        <i className="fa fa-university category-icon" aria-hidden="true"></i>
        Cultural Activities
    </Link>
    <Link to="/tours/Family-Friendly Activities" className="category-item">
        <i className="fa fa-users category-icon" aria-hidden="true"></i>
        Family-Friendly Activities
    </Link>
    <Link to="/tours/Romantic Activities" className="category-item">
        <i className="fa fa-heart category-icon" aria-hidden="true"></i>
        Romantic Activities
    </Link>
    <Link to="/tours/Nightlife Activities" className="category-item">
        <i className="fa fa-moon-o category-icon" aria-hidden="true"></i>
        Nightlife Activities
    </Link>
    <Link to="/tours/New Events" className="category-item">
        <i className="fa fa-fire category-icon" aria-hidden="true"></i>
        New Events
    </Link>
</div>

<section className="featured-tours">
    <h3>Top guided Tours</h3>
    <div className="tour-list">
      
        {tours.slice(0, showCount).map((tour) => (
            <Link to={`/tour/${tour.id}`} key={tour.id} className="tour-item">
                <img src={tour.image} alt={tour.name} />
                <h4 className="tour-name">{tour.name}</h4>
                <p className="tour-description">{tour.descriptionSmall}</p>
                <div className="tour-info-box">
                    <p className="tour-price">From: {tour.price}€</p>
                    <p className="tour-duration">❍⦿ {tour.duration}</p>
                    <p className="tour-ticketbtn">See Tickets ⌘</p>
                   
                </div>
                
            </Link>
        ))}
    </div>
    {tours.length > showCount && (
        <button className="show-more-btn" onClick={() => setShowCount(showCount + 1)}>
            Show More
        </button>
    )}
</section>



    


    </main>
  );
}

export default HomePage;