import React, { useEffect } from 'react';
import './TranslateWidget.css';

const TranslateWidget = () => {
  useEffect(() => {
    // Function to initialize the Google Translate widget
    const googleTranslateElementInit = () => {
      new window.google.translate.TranslateElement({ pageLanguage: 'en' }, 'google_translate_element');
    };

    // Check if the script already exists
    let existingScript = document.getElementById('googleTranslateScript');
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
      script.id = 'googleTranslateScript';
      script.async = true;
      document.body.appendChild(script);
    } else {
      googleTranslateElementInit();
    }

    // Cleanup function to remove the script element
    return () => {
      existingScript = document.getElementById('googleTranslateScript');
      if (existingScript) {
        existingScript.remove();
      }
    };
  }, []);  // Empty dependency array ensures this useEffect runs once, similar to componentDidMount

  return (
    <div id="google_translate_element"></div>
  );
};

export default TranslateWidget;