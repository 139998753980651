import React, { useState } from 'react';
import './ContactInfo.css';

const ContactInfo = ({ setName, setNumber, setEmail }) => {
  const [emailError, setEmailError] = useState('');

  const validateEmail = (email) => {
    // Simple regex for email validation
    const pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return pattern.test(email);
  };

  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setEmail(emailValue);

    if (!validateEmail(emailValue)) {
      setEmailError('Please enter a valid email.');
    } else {
      setEmailError('');
    }
  };

  return (
    <div className="contactInfo-container">
      <input
        type="text"
        placeholder="Name"
        onChange={(e) => setName(e.target.value)}
      />
      <input
        type="tel"
        placeholder="Phone Number"
        onChange={(e) => setNumber(e.target.value)}
      />
      <input
        type="email"
        placeholder="Email"
        onChange={handleEmailChange}
      />
      {emailError && <p className="email-error">{emailError}</p>}
    </div>
  );
};

export default ContactInfo;
