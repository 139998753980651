import React, { useState, useEffect } from 'react';
import { collection, addDoc, doc, getDoc } from "firebase/firestore";
import { useParams } from 'react-router-dom';
import { db } from './firebase';
import Calendar from './Calendar';
import HourSelector from './HourSelector';
import NumPeople from './NumPeople';
import './MontmartreTour.css';
import PaymentModal from './PaymentModal';
import ContactInfo from './ContactInfo'

// Function to get today's date in YYYY-MM-DD format
const getTodayDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};



const TourDetails = ({ tourData }) => {
  const [selectedDate, setSelectedDate] = useState(getTodayDate()); // New state variable for selected date
  const [selectedHour, setSelectedHour] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [name, setName] = useState("");
const [number, setNumber] = useState("");
const [email, setEmail] = useState("");
const closedDates = tourData.closedDates;
  const hours = tourData.hours;
  

  const [numberOfAdults, setNumberOfAdults] = useState(1);
  const totalPrice = tourData.price * numberOfAdults;

  const handleBooking = async () => {
    const bookingData = {
      tourName: tourData.name,
      email: email,
      number: number,
      name:name,
      numberOfPeople: numberOfAdults,
      bookingDate: selectedDate,
      bookingHour: selectedHour,
      price: tourData.price,
      total: totalPrice,

    };
  
    try {
      await addDoc(collection(db, "bookings"), bookingData);
    } catch (error) {
      console.error("Error adding booking: ", error);
      alert("There was an error with your booking. Please try again.");
    }
  };
  

  const handleDateChange = (date) => {
    setSelectedDate(date); // Update the selected date
  };
  const handleHourClick = (hour) => {
    setSelectedHour(hour);
  };

  const handleTimeChange = (time) => {
    // Your logic for handling time change
  };
  





  return (
    <div className="tourDetailsPage-container">
      <img src={tourData.image} alt={tourData.name} className="tourDetailsPage-image" />
    <div className="tourDetailsPage-info">
        <h2 className="tourDetailsPage-title"><i className="fas fa-map-marker-alt"></i> {tourData.name}</h2>
        <p className="tourDetailsPage-description">{tourData.description}</p>
        <p className="tourDetailsPage-price"><i className="fas fa-dollar-sign"></i> Price: {tourData.price}€</p>
        <div className="tourDetailsPage-additionalInfo">
            <p className="duration">Duration: {tourData.duration} hours</p>
            <p className="website">Website: <a href={tourData.website}>{tourData.website}</a></p>
            <p className="address">Address: {tourData.address}</p>
            <p className="kidsPrice">Kids Price: {tourData.kidsPrice}€</p>
            <p className="category">Category: {tourData.category}</p>
        </div>
        <div className="tourDetailsPage-bookingInfo">
        <Calendar 
          selectedDate={selectedDate}
          selectedTime={null}
          handleDateChange={handleDateChange}
          handleTimeChange={handleTimeChange}
          closedDates={closedDates}
          hours={hours}
        />
      </div>
      <HourSelector selectedHour={selectedHour} handleHourClick={handleHourClick} hours={hours} />
        <NumPeople setNumberOfAdults={setNumberOfAdults} />

        <ContactInfo setName={setName} setNumber={setNumber} setEmail={setEmail} />

<p className="tourDetailsPage-totalprice">
  <i className="fas fa-dollar-sign"></i> 
  Total Price: {totalPrice}€
</p>


      
       
<button 
  onClick={() => {
    handleBooking();
    setModalOpen(true);
  }} 
  className="tourDetailsPage-bookNowButton"
>
  Book Now
</button>
      <PaymentModal isOpen={isModalOpen} close={() => setModalOpen(false)} totalPrice={totalPrice} email={email} />
      </div>
    </div>
  );
  

  
};

// Main Component
function MontmartreTour() {
  const [tourData, setTourData] = useState(null);
  const { tourId } = useParams();
  const [isLoading, setIsLoading] = useState(true); // New state variable to toggle calendar display

  const handleDateChange = (date) => {
    // Handle the date change here
    console.log("Selected date:", date);
  };

  const handleTimeChange = (time) => {
    // Handle the time change here
    console.log("Selected time:", time);
  };


  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const tourDoc = await getDoc(doc(db, 'tours', tourId));
        if (tourDoc.exists()) {
          setTourData(tourDoc.data());
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
      setIsLoading(false);
    };

    fetchData();
  }, [tourId]);

  return (
    <div className="montmartre-tour">
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <>
          {tourData && <TourDetails tourData={tourData} />}
        </>
      )}
    </div>
  );
}

export default MontmartreTour





