import React, { useState, useEffect } from 'react';
import './Calendar.css';

const Calendar = ({ selectedDate, selectedTime, handleDateChange, handleTimeChange, closedDates = []  }) => {
  const today = new Date();
  const [currentMonth, setCurrentMonth] = useState(today.getMonth() + 1);
  const [currentYear, setCurrentYear] = useState(today.getFullYear());
  const [days, setDays] = useState([]);
  const [selectedDay, setSelectedDay] = useState(today.getDate());
 

  useEffect(() => {
    const firstDayOfMonth = new Date(currentYear, currentMonth - 1, 1).getDay();
    const daysInMonth = new Date(currentYear, currentMonth, 0).getDate();
    const calendarDays = [];

    for (let i = 1; i <= firstDayOfMonth + daysInMonth; i++) {
      const day = i - firstDayOfMonth;
      calendarDays.push(day > 0 ? day : null);
    }

    setDays(calendarDays);
  }, [currentMonth, currentYear]);

  useEffect(() => {
    if (selectedDay) {
      const date = `${currentYear}-${String(currentMonth).padStart(2, '0')}-${String(selectedDay).padStart(2, '0')}`;
      handleDateChange(date);
    }
  }, [selectedDay, currentMonth, currentYear]);

  const nextMonth = () => {
    if (currentMonth === 12) {
      setCurrentMonth(1);
      setCurrentYear(currentYear + 1);
    } else {
      setCurrentMonth(currentMonth + 1);
    }
  };

  const selectDay = (day) => {
    if (day) {
      setSelectedDay(day);
    }
  };

  return (
    <div  className="notranslate calendar-container">
      <div className="calendar-header">
        <span>{`${new Date(currentYear, currentMonth - 1).toLocaleString('default', { month: 'long' })} ${currentYear}`}</span>
        <button className="calendar-button" onClick={nextMonth}>Next Month</button>
      </div>
      <div className="calendar-day-names">
        {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day, index) => (
          <div key={index} className="calendar-day-name">{day}</div>
        ))}
      </div>
      <div className="calendar-grid">
  {days.map((day, index) => {
    if (day === null) {
      return <div key={index} className="calendar-day empty"></div>;
    }
    const date = `${currentYear}-${currentMonth.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
    const isClosed = closedDates.includes(date);
    const isSelected = selectedDay === day;
    return (
      <div
        key={day}
        className={`calendar-day ${isClosed ? 'closed' : 'selectable'} ${isSelected ? 'selected' : ''}`}
        onClick={() => !isClosed && selectDay(day)}
      >
        {day}
      </div>
    );
  })}
</div>
      <div className="calendar-box">
    
    <div className="selected-date">
      <strong>Selected Date:</strong> {selectedDate}
    </div>
  </div>
     
      
    </div>
  );
};

export default Calendar;

