// HeroSection.js
import React from 'react';
import parisImage from '../images/paris22Home.jpeg';
import { Link } from 'react-router-dom';

function HeroSection() {
  return (
    <section className="hero">
      <img src={parisImage} alt="Beautiful view of Paris" className="hero-image" />
      <div className="hero-content">
        <h2>Discover the Beauty of Paris</h2>
        <p>Experience the magic of the City of Lights with our curated tours.</p>
                 <Link to="/tours" className="explore-btn">Explore </Link>
        
      </div>
    </section>
  );
}

export default HeroSection;




//<input type="text" className="search-bar" placeholder="Search for activities in Paris" />