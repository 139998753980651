import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import HomePage from './components/HomePage';
import MontmartreTour from './components/MontmartreTour';
import FormPage from './components/FormPage';
import Footer from './components/Footer';
import Tours from './components/Tours';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// Make sure to use your actual publishable key
const stripePromise = loadStripe('your-publishable-key-here');

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/tour/:tourId" element={
            <Elements stripe={stripePromise}>
              <MontmartreTour />
            </Elements>
          } />
          <Route path="/form" element={<FormPage />} />
          <Route path="/tours" element={<Tours />} />  
          <Route path="/tours/:category" element={<Tours />} />  
          <Route path="/Contact" element={<FormPage />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;


